import * as React from 'react';
import { Image } from "@chakra-ui/react"
import PropTypes from "prop-types"

const SVGImage = (props) => {
    return (
        <>
            <Image mb={8} width={props.width} height={props.height} alt="" src={props.image} />
        </>
    )
  }

  SVGImage.defaultProps = {
      width: 500,
      height: 500
  }

  SVGImage.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    image: PropTypes.string
  }
  
  export default SVGImage