import * as React from 'react';
import {Box} from "@chakra-ui/layout"
import { IconButton, Image, Link } from "@chakra-ui/react"
import { FaTwitter, FaDiscord } from 'react-icons/fa'

const IconFooter = () => {
    return (
        <Box mt={4} mb={4} mx="auto">
            <Link href='https://twitter.com/dungeons_nft'>
                <IconButton colorScheme="teal" aria-label="twitter" m={2} fontSize="24px" icon={<FaTwitter />} />
            </Link>
            <Link href='https://discord.gg/8CDEveXu4B'>
                <IconButton colorScheme="teal" aria-label="discord" m={2} fontSize="24px" icon={<FaDiscord />} />
            </Link>
            <Link href='https://etherscan.io/address/0x665d9259f18b29a4ca38e9a4768cedee1429d116'>
                <IconButton colorScheme="teal" aria-label="contract" m={2} fontSize="24px" icon={<Image src="etherscan.png" w="24px" h="24px" />} />
            </Link>
            <Link href='https://opensea.io/collection/dungeonsnft'>
                <IconButton colorScheme="teal" aria-label="opensea" m={2} fontSize="24px" icon={<Image src="opensea.png" w="24px" h="24px" />} />
            </Link>
            <Link href='https://generative.xyz/generative/1001895'>
                <IconButton colorScheme="teal" aria-label="generative" m={2} fontSize="24px" icon={<Image src="hourglass.png" w="24px" h="24px" />} />
            </Link>        
        </Box>
    )
  }

  export default IconFooter


