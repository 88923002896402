import { Flex, Box, Link, Spacer } from "@chakra-ui/layout"
import { Image } from "@chakra-ui/image"
import { Stack, useDisclosure } from "@chakra-ui/react";
import navStyles from "./Navigation.module.css"

const Navigation = () => {
  return (
    <div className={navStyles.NavBar}>
      <Flex color="whiteAlpha.900" bg="gray.900" px={8} py={4} direction={["column", "row", "row", "row"]} 
            w="100%" position="sticky" mt={0}>
          <Image boxSize="32px" src="dungeon-gate.png" my="auto" mr={4}/>
          <Stack isInline spacing={4} align="baseline" w="100%" display={["block", "block", "flex", "flex"]} fontSize="xl" >
            <Link href="#home">Home</Link>
            <Link href="#details">Details</Link>
            <Link href="#examples">Examples</Link>
            {/* <Link href="#resources">Resources</Link> */}
            <Spacer w="100%" />
          </Stack>
      </Flex>
    </div>
  )
}

export default Navigation