import { ChakraProvider, Image } from "@chakra-ui/react";
import theme from "./theme";
import Layout from "./components/Layout";
import { useState } from 'react';
import Navigation from './components/Navigation';
import ProjectDetails from './components/ProjectDetails';
import SVGImage from './components/SVGImage';
import ExamplesETH from './components/ExamplesETH'
import ExamplesBTC from './components/ExamplesBTC'
import IconFooter from "./components/IconFooter";
import "@fontsource/inter";

function App() {
  // store greeting in local state
  const [svgData, setSvgData] = useState("")
  const [message, setMessage] = useState("")
  const [isError, setIsError] = useState(false)

  async function showMessage(m)
  {
    setMessage(m);
    setIsError(false);
  }

  async function showError(m)
  {
    setMessage(m);
    setIsError(true);
  }

  return (
    <ChakraProvider theme={theme}>
      <Layout>
        <Navigation />
        <ProjectDetails/>
        <Image src="divider.png" w="25%" pb={4} pt={4} mt={4} />
        <ExamplesETH />
        <Image src="divider.png" w="25%" pb={4} pt={4} mt={4} />
        <ExamplesBTC />
        <Image src="divider.png" w="25%" pb={4} pt={4} mt={4} />
        <IconFooter />
      </Layout>
    </ChakraProvider>
  );
}

export default App;
