import { Flex, Heading } from "@chakra-ui/layout"
import { Link, Image, Stack } from "@chakra-ui/react"

const ExamplesETH =() => {
    return (
      <Flex color="whiteAlpha.900" p={8} w="100%" direction="column">
        <Link id="examples" />
        <Heading mx="auto" mb={16}>Example ETH Dungeons</Heading>
        <Stack direction={["column","column","row", "row"]} mx="auto" align="center" justify="space-evenly" w="100%">
          <Image src="Sample1.jpg" boxSize={256} boxShadow="8px 8px 16px rgba(0, 0, 0, 0.5)" />
          <Image src="Sample2.jpg" boxSize={256} boxShadow="8px 8px 16px rgba(0, 0, 0, 0.5)" />
          <Image src="Sample3.jpg" boxSize={256} boxShadow="8px 8px 16px rgba(0, 0, 0, 0.5)" />
        </Stack>
      </Flex>
    )
  }

  export default ExamplesETH