import {Flex, Heading, Text} from "@chakra-ui/layout"
import { Link } from "@chakra-ui/react"

const ProjectDetails =() => {
    return (
      <Flex color="whiteAlpha.900" direction="column" px={8} pt={8}>
        <Link id="details" />
        <Heading size="2xl" mx="auto">Project Details</Heading>
        <Heading size="xl" mx="80px">Dungeons NFT</Heading>
        <Text mx="80px" fontSize="xl" py={4}>
          Dungeons NFT is a randomised generative dungeon stored on the Ethereum blockchain.
          Unlike 99% of all other NFT's, Dungeons NFT is generated entirely on-chain with no external
          storage or 3rd party servers required to host them.  So long as the Ethereum blockchain exists,
          so will Dungeons NFT.
        </Text>
        <Heading size="xl" mx="80px">Ordinal Dungeons</Heading>
        <Text mx="80px" fontSize="xl" py={4}>
          Ordinal Dungeons are Bitcoin Ordinals that have been rendered using the Ethereum Virtual Machine on chain as SVG images,
          then stored forever on-chain as Bitcoin Ordinals, each inscribed on a single satoshi.
        </Text>
      </Flex>
    )
  }

  export default ProjectDetails